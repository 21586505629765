import axiosClient from "../../config/api";

const state = {
    user_maintenance: null,
    query_params: null,
    history_fpp: {},
    kategori_im: [],
    kategori_jenis: [],
    kategori_sub: [],
    option_status_fpp: [],
    detail_memo: [],
    barangList: null,
};

const getters = {
    user_maintenance: (state) => state.user_maintenance,
    query_params: (state) => state.query_params,
    history_fpp: (state) => state.history_fpp,
    kategori_im: (state) => state.kategori_im,
    kategori_jenis: (state) => state.kategori_jenis,
    kategori_sub: (state) => state.kategori_sub,
    option_status_fpp: (state) => state.option_status_fpp,
    detail_memo: (state) => state.detail_memo,
    barangList: (state) => state.barangList,
};


const mutations = {
    setQueryParams(state, data) {
        state.query_params = data;
    },
    setUserMaintenance(state, data) {
        state.user_maintenance = data;
    },
    setHistoryFpp(state, data) {
        state.history_fpp = data;
    },
    setCategoryIM(state, data) {
        state.kategori_im = data;
    },
    setCategoryJenis(state, data) {
        state.kategori_jenis = data;
    },
    setCategorySub(state, data) {
        state.kategori_sub = data;
    },
    setOptionStatusFpp(state, data) {
        state.option_status_fpp = data;
    },
    setDetailMemo(state, data) {
        state.detail_memo = data;
    },
    setStockBarangFpp(state, data) {
        state.barangList = data;
    },
};
const actions = {
    async getHistoryFpp({ commit }, params) {
        const pusat = [
            // "GM Support",
            "General Affair",
            // "GM General Affair"
        ]
        const role = localStorage.getItem("role")
        let url;
        if (pusat.includes(role)) {
            url = 'internal-memo/memo/archive'
        } else {
            url = 'internal-memo/memo/archive'
        }
        const response = await axiosClient({
            url: url,
            params: {
                ...params,
                order_by: "DESC"
            }
        });
        const datas = response.data.data;
        const rows = datas.data.map(item => {
            const catatanTolak = item.history_ditolak ? item.history_ditolak.catatan : "-"
            const catatanSetujui = item.history_disetujui ? item.history_disetujui.catatan : "-"
            let keteranganGlobal = item.flag === 10 ? catatanTolak : catatanSetujui

            let newDate = new Date(item.updated_at);
            let countDate = newDate.setDate(newDate.getDate() + 1);

            let resultTime = new Date(countDate).getTime();
            let countDownUpdateTime = resultTime;

            let timeNow = new Date().getTime();
            let resultTimeOut = countDownUpdateTime - timeNow;

            return {
                ...item,
                timeout: resultTimeOut,
                keterangan: keteranganGlobal,
                kategori_jenis: item.kategori_jenis ? item.kategori_jenis.name : null,
                kategori_sub: item.kategori_sub ? item.kategori_sub.name : null,
                kabkot: item.cabang ? item.cabang.kabupaten_kota.name : null,
                cabang: item.cabang ? item.cabang.name : null
            }
        })
        const dataForTable = {
            rows,
            total: datas.total,
            current_page: datas.current_page,
            per_page: datas.per_page,
            from: datas.from,
            to: datas.to,
        }
        commit("setHistoryFpp", dataForTable);
        return dataForTable
    },
    async getCategoryIM({ commit }) {
        const response = await axiosClient({
            url: 'internal-memo/kategori/all'
        });
        commit("setCategoryIM", response.data.data);
        return response.data.data
    },
    async getCategoryJenis({ commit }) {
        const response = await axiosClient({
            url: 'internal-memo/kategori-jenis/all'
        });
        commit("setCategoryJenis", response.data.data);
        return response.data.data
    },
    async getCategorySub({ commit }) {
        const response = await axiosClient({
            url: 'internal-memo/kategori-sub/all'
        });
        commit("setCategorySub", response.data.data);
        return response.data.data
    },
    async getOptionStatusFpp({ commit }) {
        const data = [
            {
                id: 11,
                text: "Dijadwalkan Ulang",
            },
            {
                id: 4,
                text: "Diselesaikan",
            },
            {
                id: 2,
                text: "Disetujui",
            },
            {
                id: 3,
                text: "Diproses",
            },
            {
                id: 8,
                text: "Dibatalkan",
            },
            {
                id: 10,
                text: "Ditolak",
            },
        ]
        commit("setOptionStatusFpp", data);
        return data
    },
    async getQueryParams({ commit }, params) {
        console.log(params)
        commit("setQueryParams", params);
    },
    async getDetailMemo({ commit }, id) {
        const response = await axiosClient({
            url: 'internal-memo/memo/' + id
        });
        commit("setDetailMemo", response.data.data);
        return response.data.data
    },
    async getUserMaintenance({ commit }) {
        const response = await axiosClient({
            url: 'internal-memo/user-maintenance'
        });
        const datas = response.data.data
        const users = datas.map(item => {
            return {
                id: item.id,
                nama: item.nama,
                foto: item.foto,
            }
        })
        commit("setUserMaintenance", users);
        return users
    },
    async getStockBarangFpp({ commit }, params) {
        const response = await axiosClient({
            url: 'internal-memo/memo/getStockBarangV2',
            params: {
                kode_cabang: "0999",
                ...params,
            }
        });
        const datas = response.data.data
        commit("setStockBarangFpp", datas);
        console.log(datas)
        return datas
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
